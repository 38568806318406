<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-28 20:35:27
 * @LastEditTime: 2023-06-30 15:50
 * @Descripttion: 错题集
-->
<style lang="scss" scoped>
.wrong-questions {
    @include innerPage(40px 16px 48px 15px);
    @include pageTitle(0, 0, flex-start);

    .search-form {
        margin-left: 78px;
    }

    .statistics {
        &-wrapper {
            height: calc(100% - 94px);
            margin-top: 48px;
            box-sizing: border-box;
            padding: 0 34px 0 46px;
            overflow: hidden;
            overflow-y: auto;

        }

        &-inner {
            width: 100%;
            height: 100%;
            flex-wrap: wrap;
            @include flexBox(space-between);

            .progress,
            .statistics-echarts {
                width: calc((100% - 30px) / 2);
                height: 710px;
                background: rgba($color: #f6f7fa, $alpha: .59);
                box-sizing: border-box;
                padding: 0 40px;
            }

            .progress {
                padding-top: 36px;
                flex-direction: column;
                @include flexBox(center);

                &-stroke {
                    position: relative;
                    width: 266px;
                    height: 266px;

                    .center-text {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-size: 16px;
                        color: #535353;
                        line-height: 24px;
                        flex-direction: column;
                        @include flexBox(center);

                        .percentage {
                            font-size: 36px;
                            color: #fcb332;
                            line-height: 1em;
                            margin-bottom: 10px;

                            span {
                                font-size: 14px;
                            }
                        }
                    }
                }

                &-data {
                    width: 100%;
                    padding-top: 64px;
                    margin-top: 64px;
                    border-top: 1px solid rgba($color: #a5a5a5, $alpha: .16);
                    @include flexBox(space-between);

                    li {
                        width: calc((100% - 12px) / 3);
                        height: 146px;
                        border-radius: 10px;
                        flex-direction: column;
                        font-size: 16px;
                        line-height: 36px;
                        @include flexBox(center);

                        .pf_bold {
                            font-size: 30px;
                        }

                        &:nth-child(1) {
                            background: rgba($color: #f57070, $alpha: 0.04);

                            .pf_bold {
                                color: #e95e59;
                            }
                        }

                        &:nth-child(2) {
                            background: #c5f1e4;

                            .pf_bold {
                                color: #2ac293;
                            }
                        }

                        &:nth-child(3) {
                            background: rgba($color: #6c4ecb, $alpha: 0.07);

                            .pf_bold {
                                color: #6c4ecb;
                            }
                        }

                    }
                }
            }

            .statistics-echarts {
                .echarts-box {
                    width: 100%;
                    height: 430px;
                }

                .statistics-data {
                    border-top: 1px solid rgba($color: #a5a5a5, $alpha: .16);
                    padding-top: 24px;

                    .tips {
                        color: #636363;
                        line-height: 24px;
                        text-align: center;
                    }

                    &--list {
                        margin-top: 16px;
                        @include flexBox(center);
                    }

                    li {
                        width: calc((100% - 12px) / 3);
                        height: 146px;
                        border-radius: 10px;
                        flex-direction: column;
                        font-size: 16px;
                        line-height: 36px;
                        margin: 0 3px;
                        @include flexBox(center);

                        .pf_bold {
                            font-size: 30px;
                        }

                        &:nth-child(1) {
                            background: #c5f1e4;

                            .pf_bold {
                                color: #2ac293;
                            }
                        }

                        &:nth-child(2) {
                            background: rgba($color: #f57070, $alpha: 0.04);

                            .pf_bold {
                                color: #e95e59;
                            }
                        }
                    }
                }
            }

            .subjects {
                width: 100%;
                flex-shrink: 0;
                margin-top: 40px;
                border-top: 1px solid #efe1ea;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;

                &::after {
                    content: '';
                    width: 166px;
                    height: 340px;
                    background: linear-gradient(to left, rgba(232, 234, 243, 0.8) 0%, rgba(232, 234, 243, 0) 100%);
                    position: absolute;
                    right: 0;
                    top: 40px;
                    z-index: 2;
                }

                .subjects-swiper {
                    width: 100%;
                    height: 100%;
                    padding: 40px 166px 30px 0;
                    overflow: hidden;
                    overflow-y: visible;

                    .subject {
                        width: 240px;
                        height: 340px;
                        border-radius: 25px;
                        margin-left: 30px;
                        flex-direction: column;
                        color: #fff;
                        cursor: pointer;
                        @include flexBox(center);
                        @include dotBgPurple;

                        &:not(:first-child):hover {
                            box-shadow: 0px 2px 24px 0px rgba(23, 23, 23, 0.29);
                        }

                        &:first-child {
                            margin-left: 0;

                            &:hover {
                                box-shadow: 14px 2px 24px -18px rgb(23 23 23 / 29%), 14px -2px 24px -18px rgb(23 23 23 / 29%)
                            }
                        }

                        &:last-child {
                            margin-right: 166px;
                        }

                        &.green {
                            @include dotBgGreen;
                        }

                        &.orangered {
                            @include dotBgOrangered;
                        }

                        &.orange {
                            @include dotBgOrange;
                        }

                        &--text {
                            width: 106px;
                            height: 106px;
                            background-attachment: rgba(135, 115, 237, .6);
                            box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.15);
                            border-radius: 20px;
                            text-align: center;
                            line-height: 106px;
                            font-size: 30px;
                        }

                        &--question_num {
                            font-size: 30px;
                            line-height: 42px;
                            margin-top: 16px;
                        }

                        .label {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        .today-change {
                            width: 100%;
                            position: relative;
                            margin-top: 28px;
                            @include flexBox;

                            &::after {
                                content: '';
                                height: 24px;
                                border-left: 1px solid rgba($color: #d6d5e0, $alpha: 0.46);
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                            }

                            p {
                                width: 50%;
                                flex-direction: column;
                                line-height: 24px;
                                @include flexBox(center);

                                .num {
                                    font-size: 18px;

                                }
                            }
                        }
                    }
                }

                &-btns {
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 3;
                    flex-direction: column;
                    @include flexBox(center);

                    .el-button {
                        width: 42px;
                        min-width: 42px;
                        height: 42px;
                        padding: 0;
                        font-size: 20px;
                        margin: 0;
                        margin-bottom: 2px;

                        &--custom_warning {
                            &.swiper-button-disabled {
                                background: #f9dfb0;
                                border-color: #f9dfb0;
                                cursor: default;
                            }
                        }

                        &--custom_primary {
                            &.swiper-button-disabled {
                                background-color: #c0afee;
                                border-color: #b8a7e8;
                                cursor: default;
                            }
                        }

                    }

                }
            }
        }
    }
}
</style>

<template>
    <section class="wrong-questions">
        <div class="page-title">
            <h3>错题集</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-select v-model="searchForm.sccla_grade" placeholder="选择年级" @change="chooseGrade">
                            <el-option label="全部" :value="0" />
                            <el-option v-for="item in gradeList" :key="item.sccla_grade" :label="item.sccla_name"
                                :value="item.sccla_grade" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.sccla_id" placeholder="选择班级">
                            <el-option label="全部" :value="0" />
                            <el-option v-for="item in classList" :key="item.sccla_id" :label="item.sccla_name"
                                :value="item.sccla_id" />
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" size="medium" @click="getData">查询</el-button>
            </div>
        </div>
        <div class="statistics-wrapper">
            <div class="statistics-inner">
                <div class="progress">
                    <div class="progress-stroke">
                        <el-progress class="percentage-progress warning" :width="266" :stroke-width="30" type="circle"
                            stroke-linecap="butt" :percentage="wrongQuesInfo.all_schedule || 0" :show-text="false" />
                        <div class="center-text">
                            <p class="pf_bold percentage">{{ wrongQuesInfo.all_schedule || 0 }}<span class="pf">%</span></p>
                            <p>重做进度</p>
                        </div>
                    </div>
                    <ul class="progress-data">
                        <li>
                            <p class="pf_bold">{{ wrongQuesInfo.all_count || 0 }}</p>
                            <p>错题总数量</p>
                        </li>
                        <li>
                            <p class="pf_bold">{{ wrongQuesInfo.all_master_count || 0 }}</p>
                            <p>已掌握错题数量</p>
                        </li>
                        <li>
                            <p class="pf_bold">{{ wrongQuesInfo.all_nomaster_count || 0 }}</p>
                            <p>未掌握错题数量</p>
                        </li>
                    </ul>
                </div>
                <div class="statistics-echarts">
                    <div class="echarts-box" id="statisticsEcharts"></div>
                    <div class="statistics-data">
                        <p class="tips">今日错题状态</p>
                        <ul class="statistics-data--list">
                            <li>
                                <p class="pf_bold">{{ wrongQuesInfo.day_add_count || 0 }}</p>
                                <p>错题总数量</p>
                            </li>
                            <li>
                                <p class="pf_bold">{{ wrongQuesInfo.day_master_count || 0 }}</p>
                                <p>已掌握错题数量</p>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="subjects">
                    <div class="subjects-swiper swiper">
                        <div class="swiper-wrapper">
                            <div class="subject swiper-slide" :class="item.colorClass"
                                v-for="item in wrongQuesInfo.sub_data" :key="item.sysub_id" @click="$router.push({
                                    name: 'RESOURCES_WRONGQUESTIONS_SUBJECT', params: {
                                        subject: item.sysub_name,
                                        id: item.sysub_id,
                                    }
                                })">
                                <p class="subject--text pf_bold">{{ item.sysub_name.substring(0, 2) }}</p>
                                <p class="subject--question_num pf_bold">{{ item.all_sub_count || 0 }}</p>
                                <p class="label light">题目总数</p>
                                <div class="today-change pf_medium">
                                    <p class="append">
                                        <span class="num">{{ item.day_sub_count || 0 }}</span>
                                        <span>今日增加</span>
                                    </p>
                                    <p class="remove">
                                        <span class="num">{{ item.day_sub_master_count || 0 }}</span>
                                        <span>今日掌握</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="subjects-btns">
                        <el-button class="iconfont swiper-next" type="custom_primary" size="mini">&#xe613;</el-button>
                        <el-button class="iconfont swiper-prev" type="custom_warning" size="mini">&#xe612;</el-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { $wrongQuesInfo } from "@api/resources"
import { mapState } from "vuex"
import Swiper, { FreeMode, Navigation } from "swiper";
import "swiper/swiper.min.css";

Swiper.use([FreeMode, Navigation]);

export default {
    name: 'resources_wrongQuestions',
    computed: {
        ...mapState('common', ['gradeClass'])
    },
    watch: {
        gradeClass: {
            handler(val) {
                if (!this.$isEmpty(val)) {
                    this.gradeList = [...val];
                }
            },
            immediate: true,
        }
    },
    data() {
        return {
            gradeList: [], // 年级列表
            classList: [], // 班级列表
            searchForm: {
                sccla_grade: 0,
                sccla_id: 0,
            }, // 筛选表单
            wrongQuesInfo: {},
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 班级切换 */
        chooseGrade(val) {
            this.classList = [];
            this.searchForm.sccla_id = 0;
            this.gradeList.map(item => {
                if (item.sccla_grade == val) this.classList = [...item.children]
            })
        },
        /** 获取错题集数据 */
        async getData() {
            let { sccla_grade, sccla_id } = this.searchForm;
            let params = {};
            if (sccla_grade) params.sccla_grade = sccla_grade;
            if (sccla_id) params.sccla_id = sccla_id;

            let { data } = await $wrongQuesInfo(params);

            let chunkArr = this.$_.chunk(data.sub_data, 4);
            let classArr = ['', 'green', 'orangered', 'orange']
            chunkArr.map(arr => {
                arr.map((c_a, c_index) => {
                    c_a.colorClass = classArr[c_index]
                })
            })
            data.sub_data = [...this.$_.flattenDeep(chunkArr)]
            this.wrongQuesInfo = { ...data };
            this.$nextTick(() => {
                this.initSwiper();
                this.drawChart();
            })
        },
        /** 实例化轮播 */
        initSwiper() {
            this.subjectsSwiper = new Swiper(".subjects-swiper", {
                initialSlide: 0,
                slidesPerView: "auto",
                freeMode: true,
                noSwiping: true,
                noSwipingClass: 'subjects-swiper',
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
            });
        },
        /** 题目图表绘制 */
        drawChart() {
            let chart = this.$echarts.init(document.getElementById("statisticsEcharts"));
            let { chart: chartData } = this.wrongQuesInfo;
            let xAxisData = chartData.map(item => item.time);
            let seriesData = chartData.map(item => item.num);
            let option = {
                title: {
                    text: '题目数',
                    textStyle: {
                        color: '#282828',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    textAlign: 'left',
                    padding: [20, 0, 0, 18]
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#6340c8',
                    extraCssText: 'border-radius: 8px',
                    borderColor: '#6340c8',
                    padding: [5, 10],
                    formatter: '{c}道题',
                    textStyle: {
                        color: '#fff',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(152, 126, 232, 0.26)'
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    offset: 20,
                    data: xAxisData,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    nameTextStyle: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    offset: 5,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: ['#e9e9f4'],
                            type: 'dashed'
                        }
                    },
                    nameTextStyle: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                series: [
                    {
                        data: seriesData,
                        type: 'line',
                        symbol: 'circle',
                        showSymbol: false,
                        symbolSize: 10,
                        itemStyle: {
                            color: '#987ee8',
                            borderWidth: 2,
                            borderColor: '#fff',
                            shadowColor: 'rgba(99, 64, 200, 0.53)',
                            shadowBlur: 20
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                {
                                    offset: 0.2,
                                    color: 'rgba(152, 126, 232,0.1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(152, 126, 232,1)'
                                }
                            ])
                        }
                    }
                ]
            };
            chart.setOption(option);
        }
    }
}
</script>